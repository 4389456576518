import { Component, OnInit, ViewChild } from "@angular/core";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../../../services/auth.service";
import { Notification } from "../../../entities/notification";
import { MatBadge } from "@angular/material/badge";
import { MatIcon } from "@angular/material/icon";
import { NgFor, NgIf } from "@angular/common";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatIconButton } from "@angular/material/button";
import { MitpManagerService } from "../../../services/mitp-manager.service";
import { NotificationsService } from "../../../services/notifications.service";
import { PushNotification } from "../../../entities/push-notification";
import { MatDivider } from "@angular/material/divider";
import { first } from "rxjs";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { Router } from "@angular/router";

@Component({
	selector: "app-notifications-view",
	templateUrl: "./notifications-view.component.html",
	styleUrls: ["./notifications-view.component.scss"],
	standalone: true,
	imports: [
		MatIconButton,
		MatMenuTrigger,
		NgIf,
		MatIcon,
		MatBadge,
		MatMenu,
		NgFor,
		MatMenuItem,
		MatDivider,
		NgxTolgeeModule,
	],
})
export class NotificationsViewComponent implements OnInit {
	isEnabled = false;
	messages: Notification[] = [];
	@ViewChild("menuTrigger") menu: MatMenuTrigger;

	constructor(
		public authService: AuthService,
		private manager: MitpManagerService,
		private notificationService: NotificationsService,
		private router: Router
	) {}

	ngOnInit() {
		if (this.authService.isAuth()) {
			this.requestPermission();
			this.listen();
			this.readNotifications();
		}
		this.authService.authStatus.subscribe((status) => {
			if (status) {
				this.requestPermission();
				this.listen();
			}
		});
	}

	readNotifications(): void {
		this.notificationService
			.getNotifications()
			.pipe(first())
			.subscribe((notifications) => {
				this.messages = notifications;
			});
	}

	requestPermission(): void {
		const messaging = getMessaging();
		getToken(messaging, { vapidKey: environment.firebaseConfig.vapidKey })
			.then((currentToken) => {
				if (currentToken) {
					this.isEnabled = true;
					this.notificationService.registerFcm(currentToken).subscribe();
				} else {
					console.log("No registration token available. Request permission to generate one.");
				}
			})
			.catch((err) => {
				console.log("An error occurred while retrieving token. ", err);
			});
	}

	listen() {
		const messaging = getMessaging();
		onMessage(messaging, (payload) => {
			const message = new PushNotification();
			message.title = payload.notification.title;
			message.body = payload.notification.body;
			// this.messages.unshift(message);
		});
	}

	OnClickItem($event: MouseEvent, item: Notification) {
		$event.stopPropagation();
		this.manager.openInfoDialog(item.body_txt);
		this.menu.closeMenu();
		item.is_read = true;
		this.notificationService.readNotification(item.id).pipe(first()).subscribe();
	}

	getBadgeNumber(): number {
		return this.messages.filter((m) => !m.is_read).length;
	}

	showHistory(): void {
		this.router.navigate(["/notifications/history"]);
	}
}

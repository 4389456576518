import { Component, HostBinding, HostListener, OnInit } from "@angular/core";
import { AuthService } from "../services/auth.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { NavigationStart, Router, RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { filter } from "rxjs/operators";
import { Protocol } from "../interfaces/protocols/protocol";
import { ToolbarServiceService } from "../services/toolbar-service.service";
import { UserService } from "../services/user.service";
import { MitpManagerService } from "../services/mitp-manager.service";
import { MatDialog } from "@angular/material/dialog";
import { Notification } from "../interfaces/notification";
import { NavigationSpecialtyItem } from "../interfaces/navigation-specialty-item";
import { OrderByPipe } from "ngx-pipes";
import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { environment } from "../../environments/environment";
import { LegalService } from "../services/legal.service";
import { NotificationsViewComponent } from "../modules/notifications/notifications-view/notifications-view.component";
import { MatIconButton } from "@angular/material/button";
import { MatDivider } from "@angular/material/divider";
import { MatPrefix } from "@angular/material/form-field";
import { MatListItem, MatListSubheaderCssMatStyler, MatNavList } from "@angular/material/list";
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from "@angular/material/sidenav";
import { MatIcon } from "@angular/material/icon";
import { MatToolbar } from "@angular/material/toolbar";
import { NgFor, NgIf } from "@angular/common";
import { ThemeService } from "../services/theme.service";
import { NavigationSpecialtyService } from "../services/navigation-specialty.service";
import { UserBadgeComponent } from "../modules/users/user-badge/user-badge.component";
import { NgxTolgeeModule, TranslateService } from "@tolgee/ngx";
import { InstituteService } from "../services/institute.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
	standalone: true,
	imports: [
		NgIf,
		MatToolbar,
		MatIcon,
		MatSidenavContainer,
		MatSidenav,
		MatNavList,
		MatListSubheaderCssMatStyler,
		MatListItem,
		RouterLinkActive,
		RouterLink,
		MatPrefix,
		NgFor,
		MatDivider,
		MatSidenavContent,
		MatIconButton,
		NotificationsViewComponent,
		RouterOutlet,
		UserBadgeComponent,
		NgxTolgeeModule,
	],
})
export class AppComponent implements OnInit {
	@HostBinding("class") className = "";
	protocol: Protocol;
	title = "MedInThePocket";
	description = "";
	navOpened = !this.isSmallScreen();
	imgURL: any;
	lang = "fr";
	notifications: Notification[] = [];
	navigationSpecialtyItems: NavigationSpecialtyItem[] = [];
	firebaseApp = initializeApp(environment.firebaseConfig);
	perf = getPerformance(this.firebaseApp);
	isOffline = !navigator.onLine;

	constructor(
		public authService: AuthService,
		public userService: UserService,
		public manager: MitpManagerService,
		private legalService: LegalService,
		private instituteService: InstituteService,
		private navigationSpecialtyService: NavigationSpecialtyService,
		private router: Router,
		public toolbarService: ToolbarServiceService,
		private bpObserver: BreakpointObserver,
		private translateService: TranslateService,
		private dialog: MatDialog,
		private oPipe: OrderByPipe,
		public themeService: ThemeService
	) {
		this.themeService.setTheme();
		this.themeService.themeControl.valueChanges.subscribe((darkMode) => {
			this.className = darkMode ? "darkTheme" : "";
		});
	}

	ngOnInit(): void {
		if (this.authService.isAuth()) {
			this.loadLogo();
			this.loadNavigationItems();
			this.userService.fetchUserConfig();
		}
		this.authService.authStatus.subscribe((status) => {
			if (status) {
				this.loadLogo();
				this.loadNavigationItems();
				this.themeService.checkUserTheme();
				this.legalService.requestCguNotice();
				this.userService.fetchUserConfig();
			}
		});
		this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe((event) => {
			const regex = new RegExp("^/(protocols|glasses)/[0-9]+/edit$");
			if (regex.test(event["url"])) {
				this.toolbarService.protocolSubject.subscribe((protocol) => {
					this.title = protocol.label;
					switch (protocol.status) {
						case 0:
							this.description = this.translateService.instant("protocol.status.description.disabled");
							break;
						case 1:
							this.description = this.translateService.instant("protocol.status.description.published");
							break;
						case 2:
							this.description = this.translateService.instant("protocol.status.description.archived");
							break;
						default:
							this.description = this.translateService.instant("protocol.status.description.unknown");
					}
				});
			} else {
				this.title = "MedInThePocket";
				this.description = "";
			}
			this.closePatientIdDialog();
		});
	}

	@HostListener("window:online", ["$event"])
	@HostListener("window:offline", ["$event"])
	processOffline(event): void {
		this.isOffline = event.type === "offline";
	}

	closePatientIdDialog(): void {
		this.dialog.getDialogById("patientIdDialog")?.close();
	}

	toggle() {
		this.navOpened = !this.navOpened;
	}

	isSmallScreen() {
		return this.bpObserver.isMatched("(max-width: 900px)");
	}

	private loadLogo() {
		this.instituteService.getInstituteLogo(this.userService.getInstituteId()).subscribe((logo) => {
			const reader = new FileReader();
			reader.readAsDataURL(logo);
			reader.onload = () => {
				this.imgURL = reader.result;
			};
		});
	}

	private loadNavigationItems(): void {
		this.navigationSpecialtyService.getMyProtocolNavigationItems().subscribe((items) => {
			this.navigationSpecialtyItems = this.oPipe.transform(items, "label");
			this.manager.saveToTemp("ProtocolNavigationItems", JSON.stringify(items));
		});
	}

	getIcon(item: NavigationSpecialtyItem): string {
		if (item.icon.startsWith("material")) {
			const splittedString = item.icon.split("|");
			return splittedString[1];
		} else {
			return item.icon;
		}
	}

	navigateToHome(): void {
		this.router.navigateByUrl("/home");
	}
}

import { Component, ViewChild } from "@angular/core";
import { ProtocolsComponent } from "../protocols/protocols/protocols.component";
import { MatCard, MatCardContent, MatCardTitle } from "@angular/material/card";
import { ToolsComponent } from "../tools/tools/tools.component";
import { MatTab, MatTabGroup, MatTabLabel } from "@angular/material/tabs";
import { MatIcon } from "@angular/material/icon";
import { NgxTolgeeModule } from "@tolgee/ngx";
import { User } from "../../interfaces/users/user";
import { NamePipe } from "../../pipes/name.pipe";
import { NgIf, NgOptimizedImage } from "@angular/common";
import { MatTooltip } from "@angular/material/tooltip";
import { UptodateComponent } from "../knowledge/uptodate/uptodate.component";
import { MatDialog } from "@angular/material/dialog";
import { VidalRecosComponent } from "../knowledge/vidal/vidal-recos/vidal-recos.component";
import { VideosService } from "../videos/videos.service";
import { ProtocolService } from "../../services/protocol.service";
import { finalize, first, zip } from "rxjs";
import { IContent } from "../../interfaces/icontent";
import { UserService } from "../../services/user.service";
import { OrderByPipe } from "ngx-pipes";
import { JanusComponent } from "../protocols/janus/janus.component";
import {
	MatCell,
	MatCellDef,
	MatColumnDef,
	MatHeaderCell,
	MatHeaderCellDef,
	MatHeaderRow,
	MatHeaderRowDef,
	MatRow,
	MatRowDef,
	MatTable,
	MatTableDataSource,
} from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortHeader } from "@angular/material/sort";
import { Protocol } from "../../interfaces/protocols/protocol";
import { TagsStringPipe } from "../../pipes/tags-string.pipe";
import { Video } from "../../entities/videos/video";
import { ProtocolViewerService } from "../../services/protocol-viewer.service";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { FormsModule } from "@angular/forms";
import { MitpManagerService } from "../../services/mitp-manager.service";
import { AthenaService } from "../../services/athena.service";

@Component({
	selector: "app-home",
	standalone: true,
	imports: [
		ProtocolsComponent,
		MatCard,
		MatCardTitle,
		MatCardContent,
		ToolsComponent,
		MatTabGroup,
		MatIcon,
		MatTabLabel,
		NgxTolgeeModule,
		MatTab,
		NamePipe,
		NgOptimizedImage,
		MatTooltip,
		MatCell,
		MatCellDef,
		MatColumnDef,
		MatHeaderCell,
		MatHeaderRow,
		MatHeaderRowDef,
		MatPaginator,
		MatRow,
		MatRowDef,
		MatSort,
		MatSortHeader,
		MatTable,
		TagsStringPipe,
		MatHeaderCellDef,
		NgIf,
		MatCheckbox,
		FormsModule,
	],
	templateUrl: "./home.component.html",
	styleUrl: "./home.component.scss",
})
export class HomeComponent {
	managers: User[] = [];
	limitToDepartment = true;
	isLoading = false;
	displayedColumns: string[] = ["type", "label"];
	dataSource = new MatTableDataSource<IContent>();
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;

	constructor(
		private window: Window,
		private dialog: MatDialog,
		private videoService: VideosService,
		private protocolService: ProtocolService,
		private protocolViewer: ProtocolViewerService,
		private orderByPipe: OrderByPipe,
		private manager: MitpManagerService,
		private athena: AthenaService,
		public userService: UserService
	) {
		manager.showLoading(true);
		zip(
			this.videoService.getRecentVideos(),
			this.protocolService.getRecentProtocols(this.limitToDepartment).pipe(
				first(),
				finalize(() => manager.showLoading(false))
			)
		).subscribe(([videos, protocols]) => {
			this.dataSource.data = this.orderByPipe.transform([...videos, ...protocols], "label");
			this.dataSource.paginator = this.paginator;
			this.dataSource.sort = this.sort;
		});

		userService.getMyManagers().subscribe((managers) => {
			this.managers = this.orderByPipe.transform(managers, ["lastname", "firstname"]);
		});
	}

	triggerEmail(user: User): void {
		this.window.location.href = `mailto:${user.email}`;
	}

	openUtD(): void {
		this.dialog.open(UptodateComponent, {
			width: "80%",
			height: "70%",
			disableClose: true,
			autoFocus: false,
		});
	}

	openVidalRecos(): void {
		this.dialog.open(VidalRecosComponent, {
			width: "80%",
			height: "70%",
			disableClose: true,
			autoFocus: false,
		});
	}

	openSPD(): void {
		this.manager.openInfoDialog("Cette fonctionnalité sera bientôt disponible.");
	}

	openJanus(): void {
		this.dialog.open(JanusComponent, {
			width: "300px",
			height: "800px",
			disableClose: true,
			autoFocus: false,
			hasBackdrop: false,
		});
	}

	openContent(content: IContent): void {
		if ("thumbnail" in content) {
			this.videoService.playVideo(content as Video);
		} else {
			this.protocolViewer.viewProtocol(content as Protocol);
		}
	}

	getIcon(content: IContent): string {
		if ("thumbnail" in content) {
			return "smart_display";
		} else {
			return "assignment";
		}
	}

	isNewContent(content: IContent): boolean {
		if ("thumbnail" in content) {
			return true;
		} else {
			return (content as Protocol).updates.length === 0;
		}
	}

	refreshRecentContent($event: MatCheckboxChange): void {
		zip(
			this.videoService.getRecentVideos(),
			this.protocolService.getRecentProtocols($event.checked).pipe(first())
		).subscribe(([videos, protocols]) => {
			console.log(videos, protocols);
			this.dataSource.data = this.orderByPipe.transform([...videos, ...protocols], "label");
		});
	}

	openResearch(): void {
		this.athena.openSearchDialog();
	}
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { MITPService } from "./mitp.service";
import { Notification } from "../entities/notification";

@Injectable({
	providedIn: "root",
})
export class NotificationsService {
	constructor(
		private mitp: MITPService,
		private http: HttpClient
	) {}

	postNotification(data: any): Observable<HttpResponse<null>> {
		return this.http.post<null>(this.mitp.Notifications(), data, {
			observe: "response",
		});
	}

	registerFcm(token: string): Observable<HttpResponse<null>> {
		return this.http.post<null>(
			this.mitp.Notifications() + "/fcm/register",
			{ token: token },
			{ observe: "response" }
		);
	}

	getNotifications(): Observable<Notification[]> {
		return this.http.get<Notification[]>(this.mitp.Notifications());
	}

	readNotification(id: number): Observable<HttpResponse<null>> {
		return this.http.put<null>(this.mitp.Notifications() + `/${id}/read`, null, { observe: "response" });
	}
}

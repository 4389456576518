import {
	APP_INITIALIZER,
	enableProdMode,
	ENVIRONMENT_INITIALIZER,
	ErrorHandler,
	importProvidersFrom,
	inject,
} from "@angular/core";
import * as Sentry from "@sentry/angular";
import { tokenGetter } from "./app/app.module";
import { environment } from "./environments/environment";
import { AppComponent } from "./app/app/app.component";
import { A11yModule } from "@angular/cdk/a11y";
import { PharmacyModule } from "./app/modules/pharmacy/pharmacy.module";
import { KnowledgeModule } from "./app/modules/knowledge/knowledge.module";
import { MiscModule } from "./app/modules/misc/misc.module";
import { GoogleMapsModule } from "@angular/google-maps";
import { UsersModule } from "./app/modules/users/users.module";
import { GlassesModule } from "./app/modules/glasses/glasses.module";
import { QualityModule } from "./app/modules/quality/quality.module";
import { VideosModule } from "./app/modules/videos/videos.module";
import { DirectoryModule } from "./app/modules/directory/directory.module";
import { InstitutesModule } from "./app/modules/institutes/institutes.module";
import { SupremeAdminModule } from "./app/modules/supreme-admin/supreme-admin.module";
import { CdkDrag, CdkDragHandle } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatSliderModule } from "@angular/material/slider";
import { FilePondModule } from "ngx-filepond";
import { NgxColorsModule } from "ngx-colors";
import { MatTreeModule } from "@angular/material/tree";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { ToastrModule } from "ngx-toastr";
import { MatBadgeModule } from "@angular/material/badge";
import { MatTabsModule } from "@angular/material/tabs";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatMenuModule } from "@angular/material/menu";
import { MatStepperModule } from "@angular/material/stepper";
import { MatSelectModule } from "@angular/material/select";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDialogModule } from "@angular/material/dialog";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatChipsModule } from "@angular/material/chips";
import { MatCardModule } from "@angular/material/card";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { JwtModule } from "@auth0/angular-jwt";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { AuthComponent } from "./app/modules/auth/auth/auth.component";
import { SettingsUserComponent } from "./app/modules/users/settings-user/settings-user.component";
import { CatalogComponent } from "./app/modules/catalog/catalog.component";
import { SecureGuard } from "./app/guards/secure.guard";
import { MyProtocolsComponent } from "./app/modules/protocols/my-protocols/my-protocols.component";
import { AuthGuardService } from "./app/guards/auth-guard.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideAnimations } from "@angular/platform-browser/animations";
import { bootstrapApplication, BrowserModule } from "@angular/platform-browser";
import { provideRouter, Router, Routes } from "@angular/router";
import { provideNgxMask } from "ngx-mask";
import { DatePipe, NgOptimizedImage } from "@angular/common";
import { GroupByPipe, NgPipesModule, OrderByPipe, PercentagePipe, RoundPipe, UniquePipe } from "ngx-pipes";
import { MAT_DATE_LOCALE, MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { AuthService } from "./app/services/auth.service";
import { MitpManagerService } from "./app/services/mitp-manager.service";
import { HttpInterceptorService } from "./app/services/http-interceptor.service";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { AssetsService } from "./app/services/assets.service";
import { DevTools, FormatSimple, NgxTolgeeModule, Tolgee, TOLGEE_INSTANCE } from "@tolgee/ngx";
import { HomeComponent } from "./app/modules/home/home.component";
import { NamePipe } from "./app/pipes/name.pipe";
import { provideEcharts } from "ngx-echarts";

const appRouter: Routes = [
	{
		path: "my-protocols",
		canActivate: [AuthGuardService],
		component: MyProtocolsComponent,
	},
	{
		path: "protocols",
		loadChildren: () => import("./app/modules/protocols/protocols.module").then((m) => m.ProtocolsModule),
	},
	{
		path: "admin",
		loadChildren: () =>
			import("./app/modules/supreme-admin/supreme-admin.module").then((m) => m.SupremeAdminModule),
	},
	{
		path: "institutes",
		loadChildren: () => import("./app/modules/institutes/institutes.module").then((m) => m.InstitutesModule),
	},
	{
		path: "directory",
		loadChildren: () => import("./app/modules/directory/directory.module").then((m) => m.DirectoryModule),
	},
	{
		path: "videos",
		loadChildren: () => import("./app/modules/videos/videos.module").then((m) => m.VideosModule),
	},
	{
		path: "quality",
		loadChildren: () => import("./app/modules/quality/quality.module").then((m) => m.QualityModule),
	},
	{
		path: "tools",
		loadChildren: () => import("./app/modules/tools/tools.module").then((m) => m.ToolsModule),
	},
	{
		path: "glasses",
		loadChildren: () => import("./app/modules/glasses/glasses.module").then((m) => m.GlassesModule),
	},
	{
		path: "users",
		loadChildren: () => import("./app/modules/users/users.module").then((m) => m.UsersModule),
	},
	{
		path: "formulas",
		loadChildren: () => import("./app/modules/formulas/formulas.module").then((m) => m.FormulasModule),
	},
	{
		path: "dashboard",
		loadChildren: () => import("./app/modules/dashboard/dashboard.module").then((m) => m.DashboardModule),
	},
	{
		path: "pharmacy",
		loadChildren: () => import("./app/modules/pharmacy/pharmacy.module").then((m) => m.PharmacyModule),
	},
	{
		path: "catalog",
		canActivate: [AuthGuardService, SecureGuard],
		component: CatalogComponent,
	},
	{
		path: "settings-user",
		canActivate: [AuthGuardService],
		component: SettingsUserComponent,
	},
	{
		path: "home",
		canActivate: [AuthGuardService],
		component: HomeComponent,
	},
	{
		path: "statistics",
		loadChildren: () => import("./app/modules/statistics/statistics.module").then((m) => m.StatisticsModule),
	},
	{
		path: "misc",
		loadChildren: () => import("./app/modules/misc/misc.module").then((m) => m.MiscModule),
	},
	{ path: "auth", component: AuthComponent },
	{
		path: "",
		canActivate: [AuthGuardService],
		component: HomeComponent,
	},
	{ path: "**", redirectTo: "protocols" },
];

Sentry.init({
	dsn: "https://289becec72c61804fc0aa85c486986c9@o4507374080360448.ingest.de.sentry.io/4507374091239504",
	integrations: [],
	enabled: environment.production,
	release: environment.appVersion,
});

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			BrowserModule,
			FormsModule,
			MatSidenavModule,
			MatToolbarModule,
			MatListModule,
			MatButtonModule,
			MatIconModule,
			MatFormFieldModule,
			MatInputModule,
			MatFormFieldModule,
			ReactiveFormsModule,
			JwtModule.forRoot({
				config: {
					tokenGetter: tokenGetter,
					allowedDomains: ["api.medinthepocket.com", "localhost:8000"],
				},
			}),
			MatTableModule,
			MatPaginatorModule,
			MatSortModule,
			MatCardModule,
			MatChipsModule,
			MatOptionModule,
			MatAutocompleteModule,
			MatDialogModule,
			MatProgressSpinnerModule,
			MatExpansionModule,
			MatSelectModule,
			MatStepperModule,
			MatMenuModule,
			EditorModule,
			MatCheckboxModule,
			MatRadioModule,
			MatTabsModule,
			MatBadgeModule,
			ToastrModule.forRoot({
				progressBar: true,
				timeOut: 5000,
			}),
			MatDatepickerModule,
			MatNativeDateModule,
			ClipboardModule,
			MatTooltipModule,
			MatSlideToggleModule,
			NgPipesModule,
			MatTreeModule,
			NgxColorsModule,
			FilePondModule,
			MatSliderModule,
			ScrollingModule,
			CdkDrag,
			CdkDragHandle,
			SupremeAdminModule,
			InstitutesModule,
			DirectoryModule,
			VideosModule,
			QualityModule,
			NgOptimizedImage,
			GlassesModule,
			UsersModule,
			GoogleMapsModule,
			MiscModule,
			KnowledgeModule,
			PharmacyModule,
			A11yModule,
			NgxTolgeeModule
		),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorService,
			multi: true,
			deps: [MitpManagerService, AuthService],
		},
		{ provide: Window, useValue: window },
		MitpManagerService,
		{ provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
		MatDatepickerModule,
		MatNativeDateModule,
		{ provide: MAT_DATE_LOCALE, useValue: "fr-FR" },
		UniquePipe,
		OrderByPipe,
		NamePipe,
		PercentagePipe,
		DatePipe,
		GroupByPipe,
		RoundPipe,
		provideNgxMask(),
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
		{
			provide: Sentry.TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => {},
			deps: [Sentry.TraceService],
			multi: true,
		},
		provideAnimations(),
		provideRouter(appRouter),
		provideHttpClient(withInterceptorsFromDi()),
		{
			provide: ENVIRONMENT_INITIALIZER,
			multi: true,
			useValue: () => inject(AssetsService).init(),
		},
		{
			provide: TOLGEE_INSTANCE,
			useFactory: () => {
				return Tolgee()
					.use(DevTools())
					.use(FormatSimple())
					.init({
						language: "fr",
						// for development
						apiUrl: environment.tolgeeApiUrl,
						apiKey: environment.tolgeeApiKey,
						staticData: {
							fr: () => import("./assets/i18n/fr.json").then((m) => m.default),
							en: () => import("./assets/i18n/en.json").then((m) => m.default),
							vi: () => import("./assets/i18n/vi.json").then((m) => m.default),
							ar: () => import("./assets/i18n/ar.json").then((m) => m.default),
						},
					});
			},
		},
		provideEcharts(),
	],
}).catch((err) => console.error(err));
